import { configureStore } from "@reduxjs/toolkit";
import rightSlice from "./rightSlice";
import accountSlice from "./accountSlice";
import tabSlice from "./tabSlice";

const store = configureStore({
  reducer: {
    rights: rightSlice,
    account: accountSlice,
    tab: tabSlice,
  },
});


export default store;
