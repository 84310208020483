import React, { useRef, useState, useEffect } from "react";
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";
import axios from "axios";
import { toast } from "react-toastify";
import { REMOVE_BG, sizes } from "../constant";
import { Button } from "react-bootstrap";

const BannerPreview = ({ bannerData }) => {
  const [size, setSize] = useState("");
  const [processedImage, setProcessedImage] = useState(null);
  const imgRef = useRef();

  useEffect(() => {
    if (bannerData) {
      setSize(bannerData.size);
    }
  }, [bannerData]);
  const removeBackground = async (imageUrl, setProcessedImage) => {
    try {
      const response = await axios.get(REMOVE_BG, {
        params: {
          url: imageUrl,
        },
        responseType: "blob",
      });
  
      const url = URL.createObjectURL(response.data);
      setProcessedImage(url);
    } catch (error) {
      toast.error("Error Removing Background", error);
      throw error;
    }
  };
  useEffect(() => {
    if (bannerData?.product?.imageurl) {
      const imageUrl =
        bannerData.product.imageurl instanceof Blob
          ? URL.createObjectURL(bannerData.product.imageurl)
          : bannerData.product.imageurl;
  
      removeBackground(imageUrl, setProcessedImage);
    }
  }, [bannerData?.product?.imageurl]);
  if (!bannerData) return null;

  const {
    background,
    title,
    product,
    footer,
    titleColor,
    priceColor,
    nameFontSize,
    priceFontSize,
    titleFontSize,
    titleFontFamily,
    nameColor,
  } = bannerData;

  const selectedSize = sizes.find((s) => s.name === size);

  // Use customPrice directly if it exists; otherwise, use product.price
  const displayPrice = bannerData.customPrice || (product ? (product.price * 0.01).toFixed(2) : "");

  const downloadImageAsPng = () => {
    const img = imgRef.current;

    html2canvas(img, {
      useCORS: true,
      allowTaint: true,
      scale: 2,
      backgroundColor: null,
    }).then((canvas) => {
      const ctx = canvas.getContext("2d");

      canvas.toBlob((blob) => {
        saveAs(blob, "downloaded-banner.png");
      }, "image/png");
    });
  };

  return (
    <div>
      <div
        className={`banner-preview ${size}`}
        ref={imgRef}
        style={{
          width: selectedSize ? selectedSize.dimensions.width : "auto",
          height: selectedSize ? selectedSize.dimensions.height : "auto",
          position: "relative",
          fontFamily: titleFontFamily,
        }}
      >
        {background && (
          <img
            src={URL.createObjectURL(background)}
            alt="Background"
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: -1,
            }}
          />
        )}
        <h1 className="title" style={{ color: titleColor ,fontFamily: titleFontFamily,fontSize:titleFontSize}}>
          {title}
        </h1>

        {product && (
          <div className="container">
            <div className="imgcont">
              <p className="priceTag" style={{ color: priceColor }}>
                <span style={{ fontSize: priceFontSize,fontFamily: titleFontFamily, }}>${displayPrice}</span>
              </p>
              <img
                src={
                  processedImage ||
                  (product.imageurl instanceof Blob
                    ? URL.createObjectURL(product.imageurl)
                    : product.imageurl)
                }
                alt={product.name}
                className="transparent-image"
              />
              <p className="nameTag" style={{ color: nameColor ,fontFamily: titleFontFamily,fontSize:nameFontSize}}>
                {product.name.toUpperCase()}
              </p>
            </div>
          </div>
        )}
      </div>
      <div style={{ color: titleColor,fontFamily: titleFontFamily, }}>{footer}</div>
     
    </div>
  );
};

export default BannerPreview;
