import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import { A4_Size, downloadSizes, previewSizes, REMOVE_BG } from "../constant";
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";
import { Button } from "react-bootstrap";
import ASizeModal from "./ASizeModal";
import A4Modl from "./A4Modl";
import jsPDF from "jspdf";
import CleanBG from "../SVG/CleanBG";
import MessageSvg from "../SVG/MessageSvg";
import Starssvg from "../SVG/Starssvg";
import CustomLoader from "../Components/CustomeLoader";
import whiteBG from "../assets/images/a4.png";
import SoldOut from "../SVG/SoldOut";
import SoldOutsvg from "../SVG/SoldOutsvg";
import MeaasageLive from "../SVG/MeaasageLive";
import StarSvgLive from "../SVG/StarSvgLive";

const PdfScreens = ({
  background,
  title,
  titleColor,
  titleFontSize,
  priceFontSize,
  isChecked,
  footer,
  pdfcustomPrice,
  regularPrice,
  pdfProductDetails,
  showFooterInput,
  pdfbackground,
  pdfPriceColor,
  showTC,
  starColor,
  useUploadedBackground,
  nameFontSize,
  titleFontFamily,
  customPrice,
  description,
  nameColor,
  priceColor,
}) => {
  const [processedImage, setProcessedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const imgRefs = useRef([]);

  useEffect(() => {
    const removeBackground = async () => {
      setLoading(true);
      try {
        const imageUrl =
          pdfProductDetails[0]?.imageurl instanceof Blob
            ? URL.createObjectURL(pdfProductDetails[0]?.imageurl)
            : pdfProductDetails[0]?.imageurl;

        const response = await axios.get(REMOVE_BG, {
          params: { url: imageUrl },
          responseType: "blob",
        });
        const url = URL.createObjectURL(response.data);
        setProcessedImage(url);
      } catch (error) {
        toast.error("Image Not Found");
      } finally {
        setLoading(false);
      }
    };

    if (pdfProductDetails && pdfProductDetails[0]?.imageurl) {
      removeBackground();
    }
  }, [pdfProductDetails]);

  const downloadBannerAsPDF = async (index, productName) => {
    setDownloadLoading(true); // Show loader

    // Add a slight delay to allow the UI to update before processing the PDF
    setTimeout(async () => {
      try {
        const doc = new jsPDF("portrait", "pt", [793.7, 1122.52]);

        // Capture the selected banner
        const banner = imgRefs.current[index];
        const canvas = await html2canvas(banner);
        const imgData = canvas.toDataURL("image/png");

        // Add image to PDF
        doc.addImage(imgData, "PNG", 0, 0, 793.7, 1122.52);

        // Save the PDF with product name in the filename
        const filename = isChecked
          ? `${productName}_with_soldout.pdf`
          : `${productName}.pdf`;
        doc.save(filename);
      } catch (error) {
        console.error("Error downloading PDF:", error);
      } finally {
        setDownloadLoading(false); // Hide loader after the download process completes
      }
    }, 100); // Small delay (100ms) to allow the loader to show
  };

  const downloadBannerAsImage = async (index, productName) => {
    setDownloadLoading(true); // Set loading to true

    // Adding setTimeout to allow the UI to update
    setTimeout(async () => {
      try {
        const banner = imgRefs.current[index];
        const canvas = await html2canvas(banner);
        const imgData = canvas.toDataURL("image/png");

        // Save the image with product name in the filename
        const filename = isChecked
          ? `${productName}.png`
          : `banner_A4_Size.png`;
        saveAs(imgData, filename);
      } catch (error) {
        console.error("Error downloading image:", error);
      } finally {
        setDownloadLoading(false); // Set loading to false
      }
    }, 0); // 0ms timeout to ensure it's run after UI update
  };

  const downloadA4BannerAsPDF = async (bannerIndex, productName) => {
    setDownloadLoading(true); // Show loader

    // Delay the PDF download process slightly to allow the UI to update
    setTimeout(async () => {
      try {
        const doc = new jsPDF("portrait", "pt", [793.7, 1122.52]);

        // Capture the A4 banner
        const a4Banner = imgRefs.current[bannerIndex];
        const a4Canvas = await html2canvas(a4Banner);
        const a4ImgData = a4Canvas.toDataURL("image/png");

        // Add image to PDF
        doc.addImage(a4ImgData, "PNG", 0, 0, 793.7, 1122.52);

        // Construct file name with product name
        const fileName = isChecked
          ? `${productName}_with_soldout.pdf`
          : `${productName}.pdf`;
        doc.save(fileName);
      } catch (error) {
        console.error("Error downloading PDF:", error);
      } finally {
        setDownloadLoading(false); // Hide loader
      }
    }, 100); // Give a small delay to let the loader render (100ms should be sufficient)
  };

  const downloadA4BannerAsImage = async (bannerIndex, productName) => {
    setDownloadLoading(true); // Show loader

    setTimeout(async () => {
      try {
        // Capture the A4 banner as an image
        const a4Banner = imgRefs.current[bannerIndex];
        const a4Canvas = await html2canvas(a4Banner);
        const a4ImgData = a4Canvas.toDataURL("image/png");

        // Construct file name with product name
        const fileName = isChecked ? `${productName}.png` : `banner.png`;

        // Save image
        saveAs(a4ImgData, fileName);
      } catch (error) {
        console.error("Error downloading image:", error);
      } finally {
        setDownloadLoading(false); // Hide loader
      }
    }, 100); // Add a slight delay (100ms) to allow the loader to show
  };

  const [currentBannerType, setCurrentBannerType] = useState("");

  const handleBannerClick = (bannerType) => {
    setCurrentBannerType(bannerType);
    setShowModal(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="scrollable-form-container-live-pdf">
        <h3 className="banner-header-pdf">- A4 Size</h3>
        <div
          className="banner-preview greenline-mobile"
          style={{
            position: "relative",
            ...previewSizes.A4_Size,
          }}
          ref={(el) => (imgRefs.current[5] = el)}
          data-banner-type="A4_Size"
          onClick={() => handleBannerClick("A4_Size")}
        >
          <div>
            <img
              src={
                useUploadedBackground && pdfbackground
                  ? pdfbackground instanceof Blob
                    ? URL.createObjectURL(pdfbackground)
                    : background
                  : whiteBG
              }
              alt=""
              className="live-bnr"
            />
          </div>

          <div className="banner-content-a4">
            {title && (
              <div
                className="live-title-a4"
                style={{
                  fontSize: titleFontSize,
                  color: titleColor,
                  fontFamily: titleFontFamily,
                }}
              >
                {title}
              </div>
            )}

            {loading ? (
              <div className="loading-styling-pdf">
                <ClipLoader
                  color={"black"}
                  loading={loading}
                  alignContent="center"
                  size={50}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            ) : (
              <>
                <div className="a4-productContainer">
                  {pdfProductDetails?.map((product, index) => (
                    <div key={index}>
                      {product.name && (
                        <div
                          className="a4-nameTag"
                          style={{
                            fontSize: nameFontSize,
                            color: nameColor,
                            fontFamily: titleFontFamily,
                          }}
                        >
                          {product.name}
                        </div>
                      )}
                      {description && (
                        <div
                          className="a4-description"
                          style={{
                            fontFamily: titleFontFamily,
                          }}
                        >
                          {description}
                        </div>
                      )}

                      {pdfProductDetails?.map((product, index) => {
                        const priceInDollars =
                          (pdfcustomPrice || product.price) / 100;
                        const formattedPrice = priceInDollars.toFixed(2);

                        return (
                          (pdfcustomPrice || product.price) && (
                            <div style={{ position: "relative" }}>
                              <div className="msgSVG">
                                <MeaasageLive starColor={starColor} />
                              </div>
                              <div
                                key={index} // Ensure each element has a unique key
                                className="a4-priceTag"
                                style={{
                                  color: pdfPriceColor,
                                  fontFamily: titleFontFamily,
                                }}
                              >
                                <p>Regular Price</p>
                                <div className="price-wrapper">
                                  <div className="price-slash"></div>
                                  <div className="RegularPrice-pdf">
                                    ${regularPrice}
                                  </div>
                                </div>
                                <p>Only for Today</p>
                                <div className="Formated_Price_pdf">
                                  {pdfcustomPrice
                                    ? `$${pdfcustomPrice}`
                                    : `$${formattedPrice}`}
                                </div>
                              </div>
                            </div>
                          )
                        );
                      })}
                    </div>
                  ))}
                </div>
                {isChecked && (
                  <div
                    className="sold-out-wrapper"
                    style={{
                      position: "absolute", // Position it absolutely within the parent
                      top: "100%", // Center vertically
                      left: "50%", // Center horizontally
                      height: 800,
                      width: 800,
                      transform: "translate(-50%, -50%)", // Adjust centering
                      zIndex: 10, // Ensure it's on top of other content
                    }}
                  >
                    <SoldOut />
                  </div>
                )}

                {processedImage && (
                  <div>
                    <img
                      src={processedImage}
                      alt="Product"
                      className="a4-transparent-image"
                    />
                  </div>
                )}
              </>
            )}

            {(footer || showFooterInput) && (
              <div
                className="footer-style-a4"
                style={{
                  fontFamily: titleFontFamily,
                }}
              >
                {footer}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="buttons-container">
        <Button
          className="reg-btn mb-3 big block button-border"
          onClick={() => {
            const productName =
              pdfProductDetails?.[0]?.name || "banner_A4_Size"; // Use the first product's name or default name
            downloadBannerAsPDF(5, productName);
          }}
          disabled={downloadLoading} // Disable button while loading
        >
          {downloadLoading ? "Processing..." : "Download Banner as PDF"}
        </Button>

        <Button
          className="reg-btn mb-3 big block button-border"
          onClick={() => {
            const productName =
              pdfProductDetails?.[0]?.name || "banner_A4_Size";
            downloadBannerAsImage(5, productName);
          }}
          disabled={downloadLoading}
        >
          Download Banner as Image
        </Button>

        {downloadLoading && (
          <div className="loader-container">
            <CustomLoader isLoading={downloadLoading} />
            <p>Downloading...</p>
          </div>
        )}
      </div>

      {/* PDF Section */}

      <h3 className="banner-header-pdf">- PDF Screen</h3>
      <div>
        <div
          className="banner-preview greenline-mobile"
          style={{
            position: "relative",
            ...previewSizes.A4Size,
          }}
          ref={(el) => (imgRefs.current[6] = el)}
          data-banner-type="A4Size"
          onClick={() => handleBannerClick("A4Size")}
        >
          <div>
            <img
              src={
                useUploadedBackground && pdfbackground
                  ? pdfbackground instanceof Blob
                    ? URL.createObjectURL(pdfbackground)
                    : background
                  : whiteBG
              }
              alt=""
              className="live-bnr"
              crossOrigin="anonymous"
            />
          </div>

          <div className="banner-content-a44">
            {title && (
              <div
                className="live-title-a44"
                style={{
                  fontSize: titleFontSize,
                  color: titleColor,
                  fontFamily: titleFontFamily,
                }}
              >
                {title}
              </div>
            )}

            {loading ? (
              <div className="loading-styling-pdf">
                <ClipLoader
                  color={"black"}
                  loading={loading}
                  alignContent="center"
                  size={50}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            ) : (
              <>
                <div className="a44-productContainer">
                  {pdfProductDetails?.map((product, index) => (
                    <div key={index}>
                      {product.name && (
                        <div
                          className="a44-nameTag"
                          style={{
                            fontSize: nameFontSize,
                            color: nameColor,
                            fontFamily: titleFontFamily,
                          }}
                        >
                          {product.name}
                        </div>
                      )}
                      {/* Description outside the product container */}
                      {description && (
                        <div
                          className="a4-description"
                          style={{
                            fontFamily: titleFontFamily,
                          }}
                        >
                          {description}
                        </div>
                      )}

                      {pdfProductDetails?.map((product, index) => {
                        const priceInDollars =
                          (pdfcustomPrice || product?.price) / 100;
                        const formattedPrice = priceInDollars.toFixed(2);

                        return (
                          <div key={index}>
                            {(pdfcustomPrice || product?.price) && (
                              <div className="price-container"
                              style={{position:'relative'}}>
                                <div >
                                  <StarSvgLive starColor={starColor} />
                                </div>
                                <div
                                  className="a44-priceTag"
                                  style={{
                                    color: pdfPriceColor,
                                    fontFamily: titleFontFamily,
                                  }}
                                >
                                  <p>Regular Price</p>
                                  <div className="price-wrapper">
                                    <div className="price-slash"></div>
                                    <div className="RegularPrice-pdf">
                                      ${regularPrice}
                                    </div>
                                  </div>
                                  <p>Only for Today</p>
                                  <div className="Formated_Price_pdf">
                                    {pdfcustomPrice
                                      ? `$${pdfcustomPrice}`
                                      : `$${formattedPrice}`}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  ))}
                </div>
                {isChecked && (
                  <div
                    className="sold-out-wrapper"
                    style={{
                      position: "absolute",
                      top: "100%",
                      left: "50%",
                      height: 700,
                      width: 700,
                      transform: "translate(-50%, -50%)",
                      zIndex: 10,
                    }}
                  >
                    <SoldOutsvg />
                  </div>
                )}

                {processedImage && (
                  <div>
                    <img
                      src={processedImage}
                      alt="Product"
                      className="a44-transparent-image"
                    />
                  </div>
                )}
              </>
            )}

            {(footer || showFooterInput) && (
              <div
                className="footer-style-a44"
                style={{
                  color: titleColor,
                  fontFamily: titleFontFamily,
                }}
              >
                {footer}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Modals */}
      {showModal && (
        <div className="modal-container">
          {currentBannerType === "A4_Size" ? (
            <ASizeModal
              show={showModal}
              handleClose={handleCloseModal}
              {...{
                background,
                title,
                titleColor,
                titleFontSize,
                priceFontSize,
                footer,
                regularPrice,
                pdfbackground,
                useUploadedBackground,
                description,
                isChecked,
                pdfPriceColor,
                starColor,
                processedImage,
                showFooterInput,
                showTC,
                pdfcustomPrice,
                nameFontSize,
                pdfProductDetails,
                titleFontFamily,
                customPrice,
                nameColor,
                priceColor,
                bannerType: currentBannerType,
              }}
            />
          ) : currentBannerType === "A4Size" ? (
            <A4Modl
              show={showModal}
              handleClose={handleCloseModal}
              {...{
                background,
                title,
                titleColor,
                titleFontSize,
                priceFontSize,
                footer,
                regularPrice,
                useUploadedBackground,
                description,
                processedImage,
                isChecked,
                starColor,
                pdfbackground,
                showFooterInput,
                pdfPriceColor,
                showTC,
                pdfcustomPrice,
                nameFontSize,
                pdfProductDetails,
                titleFontFamily,
                customPrice,
                nameColor,
                priceColor,
                bannerType: currentBannerType,
              }}
            />
          ) : null}
        </div>
      )}

      <div className="buttons-container-pdf">
        <Button
          className="reg-btn mb-3 big block button-border"
          onClick={() => {
            const productName =
              pdfProductDetails?.[0]?.name || "banner_A4_Size"; // Use the name of the first product or default to a generic name
            downloadA4BannerAsPDF(6, productName);
          }}
          disabled={downloadLoading} // Disable button while loading
        >
          {downloadLoading ? "Processing..." : "Download Banner as PDF"}
        </Button>

        <Button
          className="reg-btn mb-3 big block button-border"
          onClick={() => {
            const productName =
              pdfProductDetails?.[0]?.name || "banner_A4_Size"; // Use the name of the first product or default to a generic name
            downloadA4BannerAsImage(6, productName);
          }}
          disabled={downloadLoading} // Disable button while loading
        >
          {downloadLoading ? "Processing..." : "Download Banner as Image"}
        </Button>

        {downloadLoading && (
          <div className="loader-container">
            <CustomLoader isLoading={downloadLoading} />
            <p>Downloading...</p>
          </div>
        )}
      </div>
    </>
  );
};

export default PdfScreens;
